import React, { useEffect } from "react";
import Main from "../components/Main";
import Nav from "../components/Nav";
import { Flex, Heading, Box, Text, Image } from "@chakra-ui/react";
//@ts-ignore
import apple from "../images/logos/black/Apple.svg";
//@ts-ignore
import boom from "../images/logos/black/Boom.svg";
//@ts-ignore
import nasa from "../images/logos/black/NASA.svg";
//@ts-ignore
import tesla from "../images/logos/black/Tesla.svg";
//@ts-ignore
import amonite from "../images/testimonials/amonite.svg";
//@ts-ignore
import bgtestimonials from "../images/testimonials/bgtestimonials.svg";
//@ts-ignore
import gratio from "../images/index/gratio.svg";
import Seo from "../components/Seo";
import testimonialsTags from "./testimonialsTags.json";
import Footer from "../components/Footer";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function testimonials() {
  const logos = [
    {
      logo: apple,
      testimony: (
        <Text>
          Koneqted is an independent and experimental mobile app which I wanted
          to develop sometime 2017/2018. I did a due diligence search of highly
          regarded app developers with specific set of skills and those with
          track record of launching apps which I thought contained highly
          creative features. Rogomi was the perfect partner for my app. The
          project team was accommodating all throughout the process. They not
          only worked on my ideas, but they suggested options which made the app
          efficient and appealing to its target audience. After koneqted, i
          collaborated with Rogomi on 3 other projects - - - 2 already launched
          and 1 ongoing. I am continually impressed with their work output and
          will continue to affiliate with them moving forward.
        </Text>
      ),
      source: "Bernard Gorospe, Koneqted",
    },
    {
      logo: boom,
      testimony: (
        <Text>
          Rogomi was recommended by a friend when I explained that other app
          providers I'd approached didn't truly understand the users' point of
          view for a housekeeping payroll app. Finding a capable, responsive,
          well-priced, developer is challenging, but not impossible.
          Essentially, though apps are computer based, the starting point and
          the end point are both human. The app can be a work of art internally,
          but if the human interface is not executed well, then the internal art
          goes to waste. So with Rogomi working on our apps, I feel that we're
          getting a developer that is easy to communicate with, executes the app
          very well, and most importantly understands the purpose of the app and
          the needs of the users.
        </Text>
      ),
      source: "Andrea Lanuza, Abueli Home Concierge",
    },
    {
      logo: nasa,
      testimony: (
        <Text>
          We contracted Rogomi to create for us an App that will help the
          farmers increase their yield from their farms. The app had to be easy
          to use, available also for off-line use and light enough to be
          installed on a wide range of mobile devices. They were quick to
          respond to our request and systematically brought out the app that
          suited our requirements. Even though they had to learn from scratch
          the complex science of Agriculture necessary to provide
          recommendations from the process of planting to harvesting, their
          team’s professionalism and expertise on mobile development have
          certainly satisfied our expectations. We would definitely consider
          them for our other requirements in the future and recommend them to
          our contacts.
        </Text>
      ),
      source: "Kenneth Logarta, Atlas Fertilizer Corporation",
    },
  ];

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".companies",
        start: "center 85%",
      },
    });
    const tl2 = gsap.timeline();
    const tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: ".amoniteHead",
        start: "40% center",
        scrub: 1,
      },
    });

    tl.fromTo(".companies0", { opacity: 0 }, { duration: 0.5, opacity: 1 })
      .fromTo(".companies1", { opacity: 0 }, { duration: 0.5, opacity: 1 })
      .fromTo(".companies2", { opacity: 0 }, { duration: 0.5, opacity: 1 })
      .fromTo(".companies3", { opacity: 0 }, { duration: 0.5, opacity: 1 })
      .fromTo(".companies4", { opacity: 0 }, { duration: 0.5, opacity: 1 })
      .fromTo(".companies5", { opacity: 0 }, { duration: 0.5, opacity: 1 })
      .fromTo(".companies6", { opacity: 0 }, { duration: 0.5, opacity: 1 })
      .fromTo(".companies7", { opacity: 0 }, { duration: 0.5, opacity: 1 });

    tl2
      .fromTo(".amoniteHead", { opacity: 0 }, { opacity: 1, duration: 1.5 })
      .fromTo(".services", { opacity: 0 }, { opacity: 1, duration: 0.5 })
      .fromTo(".subHeading", { opacity: 0 }, { opacity: 1, duration: 0.5 })
      .fromTo(".amonite", { opacity: 0 }, { opacity: 1, duration: 0.5 });

    tl3
      .to(
        ".amonite",
        {
          y: -70,
          duration: 0.5,
          ease: "ease",
        },
        "<"
      )
      .to(
        ".services",
        {
          y: -150,
          duration: 0.25,
          ease: "ease",
        },
        "<"
      )
      .to(
        ".subHeading",
        {
          y: -180,
          duration: 0.75,
          ease: "ease",
        },
        "<"
      )
      .to(
        ".amoniteHead",
        {
          y: -90,
          duration: 1,
          ease: "ease",
        },
        "<"
      );
  }, []);

  return (
    <>
      <Seo
        title={testimonialsTags.title}
        url={testimonialsTags.url}
        description={testimonialsTags.description}
      />
      <Main>
        <Nav active="testimonials" />
        <Flex
          w="100%"
          justifyContent="space-evenly"
          alignItems="center"
          wrap="wrap-reverse"
          h={["130vh", "140vh", "120vh", "100vh", "100vh"]}
          backgroundImage={bgtestimonials}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"contain"}
          backgroundPosition={["-30rem", "-30rem", "-30rem", "-15rem"]}
          px="2rem"
          className="amoniteHead"
        >
          <Box>
            <Image
              src={amonite}
              boxSize={"60vh"}
              objectFit={"contain"}
              transform={"rotate(45deg)"}
              className="amonite"
            />
          </Box>
          <Flex
            justifySelf={"start"}
            flexDir="column"
            maxW="600px"
            w={["100%", "100%", "40%"]}
          >
            <Heading
              color="#06667C"
              fontSize="3rem"
              fontFamily="Montserrat"
              className="services"
            >
              Testimonials
            </Heading>
            <Text
              className="subHeading"
              fontFamily="Open Sans"
              fontSize="1.5rem"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </Text>
          </Flex>
        </Flex>
        <Text
          alignSelf="center"
          fontFamily="Montserrat"
          fontSize={["2rem", "2rem", "2rem", "2.25rem", "2.25rem"]}
          m="2rem"
          fontWeight={800}
          color="#919E9C"
          w="100%"
          textAlign={"center"}
        >
          Companies We've worked with
        </Text>
        <Flex
          // boxShadow="inset 0 0 10px #000000"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          w="100%"
          borderRadius={15}
          className="companies"
          p="1rem"
          flexWrap={"wrap"}
          backgroundImage={gratio}
          backgroundSize="70%"
          backgroundRepeat={"no-repeat"}
          backgroundPosition={"500px,220px"}
        >
          {logos.map((t, i) => (
            <Flex
              className={`companies${i}`}
              m="1.5rem"
              key={i}
              alignItems="center"
              flexDirection={"column"}
              justifyContent={"center"}
              backgroundColor="#ffff"
              boxShadow={
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
              }
              borderRadius={20}
              h="550px"
            >
              <Box>
                <Image src={t.logo} w="6rem" />
              </Box>
              <Text
                fontFamily="Open Sans"
                fontSize={["1rem", "1rem", "1rem", "1.25rem", "1.25rem"]}
                m="2rem"
                color="#919E9C"
                textAlign={["center", "center", "center", "center"]}
              >
                {t.testimony}
              </Text>
              <Text
                fontFamily="Open Sans"
                fontSize={["1rem", "1rem", "1rem", "1.25rem", "1.25rem"]}
                m="2rem"
                color="#919E9C"
                textAlign={["center", "center", "center", "center"]}
              >
                - {t.source}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Footer />
      </Main>
    </>
  );
}
